import whistleblowerMain from "../../assets/whistleblowerMain.png";
import ViewCount from "../../components/ViewCount.js";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { server } from "../../config/server.js";
import Swal from "sweetalert2";
export default function Whistleblower({setRegisterForm}) {
  useEffect(() => {
    // window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [html, setHtml] = useState("");
  const handleSubmit = (e) => {
    Swal.showLoading();
    e.preventDefault();
    if (
      document.getElementById("password").value !==
      document.getElementById("confirm_password").value
    ) {
      console.log('gamet');
      
      const Toast = Swal.mixin({
        // toast: true,
        // position: "top-end",
        showConfirmButton: true,
        // backdrop: false,
        // timer: 3000,
        // animation:false,
        backdrop: `transparent`,
        // timerProgressBar: true,
        allowOutsideClick: false,
        confirmButtonColor: `#005f3b`,
        confirmButtonFontFamily: "Roboto",
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      return Toast.fire({
        // icon: "error",

        title: "Failed",
        text: `Password dan konfirmasi password harus sesuai`,
      })
    }

    const data = {
      objection_user_email: document.getElementById(
        "objection_user_email"
      ).value,
      objection_user_name: document.getElementById(
        "objection_user_name"
      ).value,
      objection_user_phone: document.getElementById(
        "objection_user_phone"
      ).value,
      objection_user_address: document.getElementById(
        "objection_user_address"
      ).value,
      objection_user_occupation: document.getElementById(
        "objection_user_occupation"
      ).value,
      objection_user_id_type: document.getElementById(
        "objection_user_id_type"
      ).value,
      objection_user_id_no: document.getElementById(
        "objection_user_id_no"
      ).value,
      password: document.getElementById("password").value,
    };
    fetch(`${server}/api/v1/objection/register`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        // access_token: localStorage.getItem("access_token"),
      },
    })
      .then((res) => res.json())
      .then(async (res) => {
        console.log(res, "promox");
        // setIsSubmitted(true)
        // setHtml(res)

        if (res.status) {
          const Toast = Swal.mixin({
            // toast: true,
            // position: "top-end",
            showConfirmButton: true,
            // backdrop: false,
            // timer: 3000,
            // animation:false,
            backdrop: `transparent`,
            // timerProgressBar: true,
            allowOutsideClick: false,
            confirmButtonColor: `#005f3b`,
            confirmButtonFontFamily: "Roboto",
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            // icon: "error",

            title: "Success",
            text: "User Registration Success",
          }).then((result) => {
            if (result.isConfirmed) {
              window.scrollTo(0, 0);
              setRegisterForm(false)

              // navigate(-1);
            }
          });
        } else {
          const Toast = Swal.mixin({
            // toast: true,
            // position: "top-end",
            showConfirmButton: true,
            // backdrop: false,
            // timer: 3000,
            // animation:false,
            backdrop: `transparent`,
            // timerProgressBar: true,
            allowOutsideClick: false,
            confirmButtonColor: `#005f3b`,
            confirmButtonFontFamily: "Roboto",
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            // icon: "error",

            title: "Failed",
            text: `${res.data.message}`,
          }).then((result) => {
            if (result.isConfirmed) {
          
              console.log("a1");
              // navigate('/app/login')
            }
          });
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {" "}
      {/* <button
        onClick={(e) => {
          navigate(-1);
        }}
        class="btn mx-3 px-3 "
        style={{
          backgroundColor: "#005F3B",
          color: "white",
          borderRadius: "50rem",
          fontFamily: "Roboto",
          fontSize: 18,
          position: "absolute",
          left: "5%",
        }}
      >
        Back
      </button> */}
      <div class=" pageText mx-auto h100 justify-content-center">
        <h3 style={{ fontFamily: "Roboto" }} className="text-left mb-4">
          Form Pendaftaran Permohonan Keberatan Informasi
        </h3>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div
            class="w100 flex justify-content-between"
            style={{
              flexWrap: "wrap",
            }}
          >
            <div style={{ flexBasis: "100%" }}>
              <label
                style={{ fontWeight: 500 }}
                className="text-left mb-2 pageText"
              >
                Nama Lengkap
              </label>
              <input
                style={{
                  borderRadius: 12,
                  border: "1px solid #005F3B wb_input",
                }}
                required
                // pattern="[^' ']+"
                type="text"
                id="objection_user_name"
                // value={name}
                // onChange={(e) => {
                //   setTitle(e.target.value);
                // }}
                class="form-control py-2  mb-3 addForm "
                aria-describedby="passwordHelpBlock"
                placeholder="Nama Lengkap"
              />
            </div>
            <div style={{ flexBasis: "48%" }}>
              <label
                style={{ fontWeight: 500 }}
                className="text-left mb-2 pageText"
              >
                Pekerjaan
              </label>
              <input
                style={{
                  borderRadius: 12,
                  border: "1px solid #005F3B wb_input",
                }}
                required
                pattern="[^' ']+"
                id="objection_user_occupation"
                // value={name}
                // onChange={(e) => {
                //   setTitle(e.target.value);
                // }}
                class="form-control py-2  mb-3 addForm "
                aria-describedby="passwordHelpBlock"
                placeholder="Pekerjaan"
              />
            </div>
            <div style={{ flexBasis: "48%" }}>
              <label
                style={{ fontWeight: 500 }}
                className="text-left mb-2 pageText"
              >
                No Telp
              </label>
              <input
                style={{
                  borderRadius: 12,
                  border: "1px solid #005F3B wb_input",
                }}
                required
                pattern="[^' ']+"
                id="objection_user_phone"
                // value={name}
                // onChange={(e) => {
                //   setTitle(e.target.value);
                // }}
                class="form-control py-2  mb-3 addForm "
                aria-describedby="passwordHelpBlock"
                placeholder="No Telp"
              />
            </div>
            <div style={{ flexBasis: "48%" }}>
              <label
                style={{ fontWeight: 500 }}
                className="text-left mb-2 pageText"
              >
                No Identitas
              </label>
              <input
                style={{
                  borderRadius: 12,
                  border: "1px solid #005F3B wb_input",
                }}
                required
                pattern="[^' ']+"
                id="objection_user_id_no"
                // value={name}
                // onChange={(e) => {
                //   setTitle(e.target.value);
                // }}
                class="form-control py-2  mb-3 addForm "
                aria-describedby="passwordHelpBlock"
                placeholder="No Identitas"
              />
            </div>
            <div style={{ flexBasis: "48%" }}>
              <label
                style={{ fontWeight: 500 }}
                className="text-left mb-2 pageText"
              >
                Tipe Identitas
              </label>
              <select
                required
                class="form-control form-control py-2  mb-3 addForm br10"
                      id="objection_user_id_type"
              >
                {/* <option value="">--Please choose an option--</option> */}
                <option value="KTP">KTP</option>
                <option value="SIM">SIM</option>
                <option value="Paspor">Paspor</option>
              </select>
            </div>
            <div style={{ flexBasis: "100%" }}>
              <label
                style={{ fontWeight: 500 }}
                className="text-left mb-2 pageText"
              >
              Alamat
              </label>
              <input
                style={{
                  borderRadius: 12,
                  border: "1px solid #005F3B wb_input",
                }}
                required
                pattern="[^' ']+"
                type="text"
                id="objection_user_address"
                // value={name}
                // onChange={(e) => {
                //   setTitle(e.target.value);
                // }}
                class="form-control py-2  mb-3 addForm "
                aria-describedby="passwordHelpBlock"
                placeholder="Alamat"
              />
            </div>
            <div style={{ flexBasis: "100%" }}>
              <label
                style={{ fontWeight: 500 }}
                className="text-left mb-2 pageText"
              >
                Email
              </label>
              <input
                style={{
                  borderRadius: 12,
                  border: "1px solid #005F3B wb_input",
                }}
                required
                pattern="[^' ']+"
                type="email"
                id="objection_user_email"
                // value={name}
                // onChange={(e) => {
                //   setTitle(e.target.value);
                // }}
                class="form-control py-2  mb-3 addForm "
                aria-describedby="passwordHelpBlock"
                placeholder="Email"
              />
            </div>
            {/* <div style={{ flexBasis: "100%" }}>
              <label
                style={{ fontWeight: 500 }}
                className="text-left mb-2 pageText"
              >
                Username
              </label>
              <input
                style={{
                  borderRadius: 12,
                  border: "1px solid #005F3B wb_input",
                }}
                required
                pattern="[^' ']+"
                id="objection_user_name"
                // value={name}
                // onChange={(e) => {
                //   setTitle(e.target.value);
                // }}
                class="form-control py-2  mb-3 addForm "
                aria-describedby="passwordHelpBlock"
                placeholder="Username"
              />
            </div> */}

            <div class="mb-5" style={{ flexBasis: "48%" }}>
              <label
                style={{ fontWeight: 500 }}
                className="text-left mb-2 pageText"
              >
                Password
              </label>
              <input
                style={{
                  borderRadius: 12,
                  border: "1px solid #005F3B wb_input",
                }}
                required
                pattern="[^' ']+"
                type="password"
                id="password"
                // value={name}
                // onChange={(e) => {
                //   setTitle(e.target.value);
                // }}
                class="form-control py-2  mb-3 addForm "
                aria-describedby="passwordHelpBlock"
                placeholder="Password"
              />
            </div>
            <div style={{ flexBasis: "48%" }}>
              <label
                style={{ fontWeight: 500 }}
                className="text-left mb-2 pageText"
              >
               Confirm Password
              </label>
              <input
                style={{
                  borderRadius: 12,
                  border: "1px solid #005F3B wb_input",
                }}
                required
                pattern="[^' ']+"
                type="password"
                id="confirm_password"
                // value={name}
                // onChange={(e) => {
                //   setTitle(e.target.value);
                // }}
                class="form-control py-2  mb-3 addForm "
                aria-describedby="passwordHelpBlock"
                placeholder="Password"
              />
            </div>
          </div>

          <button
            // onClick={(e) => {
            //   console.log("infpo");
            //   e.preventDefault();
            //   localStorage.setItem("token", "master");
            //   navigate("/whistleblower");
            // }}
            class="w100 mt-3"
            type="submit"
            style={{
              height: 54,
              backgroundColor: "#005F3B",
              fontFamily: "Roboto",
              fontWeight: 600,
              color: "white",
              border: "0px solid transparent",
              borderRadius: 12,
            }}
          >
            Masuk
          </button>
        </form>
      </div>
    </>
  );
}
