import React, { useEffect, useState } from "react";
import { server } from "../../config/server.js";
import ViewCount from "../../components/ViewCount.js";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import logistikMain from "../../assets/logistikMain.png";

export default function Foto() {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [album, setAlbum] = useState([]);
  const [selectedAlbum, setSelectedAlbum] = useState();
  const [header, setHeader] = useState({});
  const [buttonPrev, setButtonPrev] = useState(true);
  const [buttonNext, setButtonNext] = useState(true);
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    if (album[selectedAlbum]?.content_files.length <= 1) {
      console.log(document.getElementsByClassName("yarl__button"));
      setButtonNext(false);
      setButtonPrev(false);
    } else {
      setButtonPrev(true);
      setButtonNext(true);
    }
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      // Fetch header data
      const headerResponse = await fetch(`${server}/api/v1/content/list/42`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const headerResult = await headerResponse.json();
      if (headerResult.status) {
        setHeader(headerResult.data[0] || {});
      } else {
        throw new Error("Failed to fetch header data");
      }

      // Fetch album data
      const albumResponse = await fetch(`${server}/api/v1/cms/content/2/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const albumResult = await albumResponse.json();
      if (albumResult.status) {
        const formattedAlbum = formatAlbumData(albumResult.data);
        setAlbum(formattedAlbum);
      } else {
        throw new Error("Failed to fetch album data");
      }
    } catch (err) {
      console.log("Error fetching data:", err);
    }
  };

  const formatAlbumData = (data) => {
    return data.map((album) => {
      return {
        ...album,
        content_files: album.content_files.map((file) => ({
          ...file,
          src: file.public_link,
        })),
      };
    });
  };

  const handleAlbumClick = (photos, title, i) => {
    galleryModal(photos, title);
    setSelectedAlbum(i);
  };

  const galleryModal = (photos, title) => {
    let modal = MySwal.mixin({
      width: "fit-content",
      height: "fit-content",
      customClass: "galleryModal",
      allowOutsideClick: false,
      showClass: {
        backdrop: "swal2-noanimation",
        popup: "animated fadeInDown faster",
        icon: "animated heartBeat delay-1s",
      },
      html: (
        <div>
          <div
            className="w100 py-4 modalTitle pageText"
            style={{ fontWeight: 600, color: "#005f3b" }}
          >
            {title}
          </div>
          <div
            className="center-vertical mx-auto text-center popupFotoContainer"
            style={{ minHeight: "50vh" }}
          >
            {photos.map((img, i) => (
              <img
                key={i}
                onClick={() => {
                  setLightboxOpen(true);
                  setSelectedImage(i);
                }}
                className="galleryImg px-2 py-2 mb-5 mx-3"
                src={img.public_link}
                alt={`Photo ${i + 1}`}
              />
            ))}
          </div>
          <button
            className="btn mx-3 px-5"
            style={{
              borderRadius: "50rem",
              fontFamily: "Roboto",
              fontSize: 18,
              border: "1px solid #005F3B",
              background: "#005F3B",
              color: "white",
            }}
            onClick={() => {
              MySwal.close();
            }}
          >
            Back
          </button>
        </div>
      ),
      showConfirmButton: false,
      heightAuto: false,
    });
    return modal.fire();
  };

  const backgroundImage = header.content_header_link
    ? header.content_header_link.replace(/ /g, "%20")
    : null;
  const contentTitle = header.content_title ? header.content_title : "";
  const contentViews = header.views ? header.views : "0";

  return (
    <>
      <Lightbox
        open={lightboxOpen}
        close={() => setLightboxOpen(false)}
        slides={album[selectedAlbum]?.content_files || []}
        plugins={[Thumbnails]}
        index={selectedImage}
        render={{
          buttonPrev: buttonPrev ? undefined : () => null,
          buttonNext: buttonNext ? undefined : () => null,
        }}
      />
      <img
        class="w100"
        style={{ borderRadius: 20 }}
        src={backgroundImage}
      ></img>
      <div className="pageTitle">{contentTitle}</div>
      <div className="articleContainer pageText">
        <div className="list image">
          <div className="w100 fotoCardContainer">
            {album.map((photos, index) => (
              <div
                key={index}
                className="fotoCard mx-3 mb-4"
                onClick={() =>
                  handleAlbumClick(
                    photos.content_files,
                    photos.content_title,
                    index
                  )
                }
              >
                <div
                  className="albumThumbnail"
                  style={{
                    backgroundImage: `url("${
                      photos.content_files[0]?.public_link || ""
                    }")`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    cursor: "pointer",
                    backgroundColor: "transparent",
                  }}
                ></div>
                <div className="cardName greenText center-vertical pageText greenText">
                  {photos.content_title}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ViewCount count={contentViews} />
    </>
  );
}
