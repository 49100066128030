import whistleblowerMain from "../../assets/whistleblowerMain.png";
import ViewCount from "../../components/ViewCount.js";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
export default function WBSMenu({ backButton = true,setRegisterForm ,setLoginPI,setForm,loginPI}) {
  const [isLogin, setIsLogin] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    // window.scrollTo(0, 0);
    if (localStorage.token) {
      setIsLogin(true);
    }
  }, []);
  const data = [{}, {}, {}, {}];
  return (
    <>
      <div class="w100 text-center">

        {loginPI ? (
          <button
            onClick={(e) => {
             setForm(true)
            }}
            class="btn mx-3 px-5 "
            style={{
              backgroundColor: "#005F3B",
              color: "white",
              borderRadius: "50rem",
              fontFamily: "Roboto",
              fontSize: 18,
            }}
          >
            Buat Permohonan Keberatan Informasi
          </button>
        ) : null}
        {!loginPI? (
          <button
            onClick={(e) => {
              setRegisterForm(true)
              // navigate("/app/register");
            }}
            class="btn mx-3 px-5 "
            style={{
              backgroundColor: "#EF582C",
              color: "white",
              borderRadius: "50rem",
              fontFamily: "Roboto",
              fontSize: 18,
            }}
          >
            Register
          </button>
        ):null}
        {loginPI ? (
          <button
            onClick={(e) => {
           setForm(false)
            }}
            class="btn mx-3 px-5 "
            style={{
              backgroundColor: "#EF582C",
              color: "white",
              borderRadius: "50rem",
              fontFamily: "Roboto",
              fontSize: 18,
            }}
          >
            Status
          </button>
        ) : null}
        {loginPI ? (
          <button
            onClick={(e) => {
              setIsLogin(false);
              localStorage.removeItem('token_pki')
              localStorage.removeItem('data_pki')
              setLoginPI(false)
              window.scrollTo(0, 0);
              // navigate('/suara-pelanggan/whistleblower');
            }}
            class="btn mx-3 px-5 greenText"
            style={{
              borderRadius: "50rem",
              fontFamily: "Roboto",
              fontSize: 18,         color: "white",
              border: "1px solid #005F3B",
            }}
          >
            Logout
          </button>
        ) : (
          <button
            onClick={(e) => {
        setRegisterForm(false)
            }}
            class="btn mx-3 px-5 greenText"
            style={{
              borderRadius: "50rem",
              fontFamily: "Roboto",
              fontSize: 18,         color: "white",
              border: "1px solid #005F3B",
            }}
          >
            Login
          </button>
        )}
      </div>
    </>
  );
}
